import React, { useContext } from 'react';
import Select from 'react-select';
import TimeZoneContext from 'web/components/timezone/TimeZoneContext';
import themeConstants from 'web/styles/themeConstants';
import themeVars from 'web/styles/themeVars.css';
import type { TimezoneOption } from './getTimeZoneOptions';

const TimezoneSelect = ({ className }: { className?: string }) => {
  const { timeZone, onTimeZoneChange, options } = useContext(TimeZoneContext);

  const selected = options.find((option) => option.value === timeZone);
  return (
    <Select
      className={className}
      options={options}
      menuPlacement="top"
      value={selected}
      styles={{
        control: (provided) => ({
          ...provided,
          border: 'none',
          cursor: 'pointer',
          color: themeVars.button.color.secondary as string,
          '&:focus': {
            color: themeVars.button.color.secondaryFocus as string,
          },
          '&:hover': {
            color: themeVars.button.color.secondaryHover as string,
          },
          '&:active': {
            opacity: '0.8',
          },
        }),
        singleValue: (provided) => ({
          ...provided,
          color: 'inherit',
        }),
        valueContainer: (provided) => ({
          ...provided,
          fontWeight: 'bold',
        }),
        indicatorsContainer: () => ({
          display: 'none',
        }),
        option: (provided, state) => ({
          ...provided,
          fontWeight: state.isSelected ? themeConstants.typography.button.fontWeight : 'inherit',
          color: state.isSelected ? (themeVars.button.color.primary as string) : 'inherit',
          background: state.isSelected ? (themeVars.button.background.primary as string) : 'none',
          '&:hover': {
            background: themeVars.color.accentBackground as string,
          },
        }),
        container: (provided) => ({
          ...provided,
          zIndex: 10,
        }),
      }}
      onChange={(selectedValue, meta) => {
        if (meta.action === 'select-option') {
          const { value, offset } = selectedValue as TimezoneOption;
          onTimeZoneChange(value, offset);
        } else {
          console.error('TimezoneSelect fired an unsupported onChange event:', meta);
        }
      }}
    />
  );
};

export default TimezoneSelect;
